import React from "react"
import { Helmet } from "react-helmet"

export default function NotFound() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Ken Bostoen</title>
          <html lang="en" className="html_dark" />
          <meta
            name="description"
            content="Ken bostoen Portfolio Software engineer"
          />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Poppins"
          ></link>
        </Helmet>
  
        THIS PAGE DOES NOT EXIST
      </>
    )
  }
  